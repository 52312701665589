import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import s from './Header.module.scss'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { localArray, LocalType, LocalMap } from '../../config'
import cn from 'classnames'
import useLocale from '../../hooks/useLocale'
import MenuItem from '../MenuItem'
import { Logo, Close, ChevronDown, Sure } from '@/icons'
import Menu from '@/icons/header/menu.svg'
import { useScroll } from '@/hooks/use-scroll'
import LangMap from '@/icons/header/lang_black.svg'

type subNavName = 'Home' | 'Products' | 'FSI Solution' | 'AboutUs' | 'Resources' | 'News' | 'lang' | 'Event' | '' | 'Solutions'
type HeaderTheam = 'white' | 'none'
type Iprops = {
  isH5?: boolean
}
type ISolotionItem = {
  link: string
  desc: string
}
type ISolotionprops = {
  title: string
  list: ISolotionItem[]
  className?: string
  onClickMenu?: (href: string, name: subNavName) => void
}

const pageBanner2 = ['/blog/[id]', '/events/[id]', '/news/[id]', '/Contact_us', '/privacyPolicy', '/job', '/about', '/team', '/news', '/blog', '/marketupdates', '/events']
const pathOnlyZhAndEn = ['blog', 'news', 'events', 'marketupdates']

const SolotionItem = (props: ISolotionprops) => {
  const router = useRouter()
  const { title = '', list = [], className = '', onClickMenu = null } = props
  const menuClick = useCallback(
    (href: string, name: subNavName) => {
      // e.stopPropagation && e.stopPropagation()
      onClickMenu?.(href, href.indexOf('http') != -1 ? '' : name)
    },
    [onClickMenu]
  )
  return (
    <div className={cn(s.menuItem, className)}>
      <div className={s.menuTitle}>{title}</div>
      {list?.map((item: ISolotionItem, index: number) => (
        <Link key={index} href={item.link}>
          <a
            onClick={() => menuClick(item.link, 'Products')}
            className={cn(s.menuDesc, { [s.menuDescCurrent]: router.pathname.includes(item.link), [s.menuDesc_ar]: router.locale == 'ar' })}
            target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}
            title={item.link}
            aria-label="index"
          >
            {item.desc}
          </a>
        </Link>
      ))}
    </div>
  )
}

const Header = (props: Iprops) => {
  const { isH5 } = props
  const router = useRouter()
  const { t } = useLocale()
  const refWarpper = useRef<any>()
  const [checkHref, setCheckHref] = useState<string>('')
  const [showSubNav, setShowSubNav] = useState<subNavName>('') //当前的hover
  const [clickNav, setClickNav] = useState<subNavName>('') //当前点击的title
  const [headerTheam, setHeaderTheam] = useState<HeaderTheam>(isH5 ? 'white' : 'none') //背景色是透明还是白色
  const [direction, setDirection] = useState<boolean>(false)
  const [scrollTop, setScrollTop] = useState<number>(0)

  useScroll((scroll: any) => {
    if (scrollTop != scroll.targetScroll) {
      setDirection(scroll.direction == 1)
      setScrollTop(scroll.targetScroll)
    }
  })
  useEffect(() => {
    const moveWheel = (e: any) => {
      e.wheelDelta && setDirection(e.wheelDelta <= 0)
    }
    // 解决ScorllSlideOpacity翻页header不变化的问题
    const Ele = document.getElementById('ScorllSlideOpacity')
    if (isH5 != null && !isH5) {
      Ele?.addEventListener('wheel', moveWheel, {
        passive: true
      })
    }
    return () => {
      Ele?.removeEventListener('wheel', moveWheel)
    }
  }, [direction, isH5])
  // 根据滑动调整nav高度
  const [changeHeight, setChangeHeight] = useState<boolean>(false) //是否改变高度
  const [changeHidden, setChangeHidden] = useState<boolean>(false) //是否隐藏
  //h5部分
  const [isMenuShow, setIsMenuShow] = useState(false)
  const showMenu = useCallback(() => {
    if (!isMenuShow) {
      setShowSubNav('')
      setClickNav('')
    }
    setIsMenuShow(!isMenuShow)
  }, [isMenuShow])

  useEffect(() => {
    //设置滚动多高隐藏或者展示header
    let changeTop = document.documentElement.clientHeight / 4
    if (pageBanner2.includes(router.pathname)) {
      changeTop = refWarpper.current.clientHeight
    }
    if (scrollTop > changeTop - refWarpper.current.clientHeight) {
      !changeHeight && setChangeHeight(true)
    } else if (scrollTop <= changeTop - refWarpper.current.clientHeight) {
      changeHeight && setChangeHeight(false)
    }

    setChangeHidden(scrollTop >= changeTop - refWarpper.current.clientHeight)
    !isH5 && setShowSubNav('')
  }, [changeHeight, isH5, router.pathname, scrollTop])

  useEffect(() => {
    if (typeof window === 'undefined') return
    setCheckHref(router.route)
    setIsMenuShow(false)
    if (router.pathname == '/') {
      setClickNav('')
    } else {
      const path = router.pathname.split('/')?.[1]
      let clicknav = '' as subNavName
      if (path == 'product' || path == 'solution') {
        clicknav = 'Products'
      } else if (path == 'solutions') {
        clicknav = 'Solutions'
      } else if (path == 'marketupdates' || path == 'news' || path == 'blog') {
        clicknav = 'Resources'
      } else if (path == 'about' || path == 'team' || path == 'job') {
        clicknav = 'AboutUs'
      } else if (path == 'events') {
        clicknav = 'Event'
      }
      setClickNav(clicknav)
    }
  }, [router.pathname, router.route])
  const handleMouseEnter = useCallback(
    (type: subNavName) => {
      if (type !== 'lang') {
        setHeaderTheam('white')
      }
      !isH5 && setShowSubNav(type)
    },
    [isH5]
  )
  const handleMouseLeave = useCallback(() => {
    setHeaderTheam('none')
    !isH5 && setShowSubNav('')
  }, [isH5])
  const onClickTab = useCallback(
    (type: subNavName) => {
      setShowSubNav(showSubNav == type ? '' : type)
    },
    [showSubNav]
  )
  const changeLocale = useCallback(
    (nextLocale: string) => {
      window.location.href = '/' + nextLocale + router.asPath
    },
    [router.asPath]
  )
  const hideMenu = useCallback(
    (href: string, name: subNavName) => {
      setCheckHref(href)
      isH5 && setIsMenuShow(false)
      !isH5 && setShowSubNav('')
      name && setClickNav(name)
    },
    [isH5]
  )

  const productList = useMemo(() => {
    return [
      { link: '/product/exchange', desc: t('Digital Assets Exchange CEX') },
      { link: '/product/dex', desc: t('Decentralized Exchange DEX') },
      { link: '/product/liquidity', desc: t('Liquidity-as-a-service') },
      { link: '/product/wallet', desc: t('Wallet-as-a-service') },
      { link: '/product/nft', desc: t('NFT Marketplace') },
      { link: '/product/kyt', desc: t('Know-Your-Transaction KYT') }
    ]
  }, [t])

  const serciveList = useMemo(() => {
    return [
      { link: '/solution/AssetTokenization', desc: t('Asset Tokenization') },
      { link: '/solution/GamingandMetaverse', desc: t('Gaming and Metaverse') },
      { link: '/solution/SmartWeb3Banking', desc: t('Smart Web3 Banking') }
    ]
  }, [t])

  const custodyList = useMemo(() => {
    return [
      { link: '/product/custody', desc: t('ChainUp Custody') },
    ]
  }, [t])

  const managementList = useMemo(() => {
    return [{ link: '/product/investment', desc: t('ChainUp Investment') }]
  }, [t])

  const web3List = useMemo(() => {
    return [
      { link: '/product/crypto-mining', desc: t('Crypto Mining') },
      { link: 'https://cloud.chainup.com/', desc: t('Staking') },
      { link: 'https://cloud.chainup.com/blockapi', desc: t('Blockchain API') },
      // { link: '/product/ipfs', desc: t('Distributed Storage') },

    ]
  }, [t])

  const solutionsList = useMemo(() => {
    return [
      [
        {
          title: t('Central Banks'),
          link: '/solutions/central-banks'
        },

        {
          title: t('Commercial Banks'),
          link: '/solutions/commercial-banks'
        },
        {
          title: t('Retail Banks'),
          link: '/solutions/retail-banks'
        },

        {
          title: t('Investment Banks'),
          link: '/solutions/investment-banks'
        }
      ],
      [
        {
          title: t('Digital Asset Exchanges'),
          link: '/solutions/digital-asset-exchanges'
        },
        {
          title: t('Asset Management Firms'),
          link: '/solutions/asset-management-firms'
        },
        {
          title: t('Payment Platforms'),
          link: '/solutions/payment-platforms'
        },
        {
          title: t('Hedge Funds'),
          link: '/solutions/hedge-funds'
        }
      ],
      [
        {
          title: t('OTC/Investment Brokerage Platforms'),
          link: '/solutions/otc-investment-borakage-platforms'
        },
        {
          title: t('Venture Capital/Private Equity Firms'),
          link: '/solutions/venture-capital-private-equity-firms'
        }
      ]
    ]
  }, [t])
  return (
    <>
      <div className={cn(s.warpper, {})} data-lenis-prevent>
        <header
          ref={refWarpper}
          className={cn({
            [s.headerHidden]: changeHidden && direction && !isH5 && !(scrollTop <= 0)
          })}
        >
          <div
            className={cn(s.header, {
              [s.headerBg]: isH5 || headerTheam === 'white' || changeHeight,
              [s.changeHeader]: isMenuShow
            })}
          >
            <nav className={s.navbar}>
              <div
                className={s.logo}
                onMouseEnter={() => {
                  setHeaderTheam('white')
                }}
                onMouseLeave={() => {
                  setHeaderTheam('none')
                }}
              >
                <Link href="/" locale={router.locale}>
                  <a aria-label="h5 index">
                    <Logo />
                  </a>
                </Link>
              </div>

              <div className={cn(s.nav, { [s.h5Nav]: isMenuShow })}>
                {/* Products */}
                <div
                  className={cn(s.navItem, {
                    [s.navItemHover]: showSubNav === 'Products' && !isH5,
                    [s.navItemActive]: clickNav == 'Products' && !isH5,
                    [s.navItemH5Hover]: showSubNav === 'Products' && isH5
                  })}
                  onMouseEnter={() => handleMouseEnter('Products')}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => {
                    onClickTab('Products')
                  }}
                >
                  <div className={s.menuBox}>
                    <div className={cn(s.innerWarpper)}>
                      <span className={cn(s.inner)}>
                        <span className={s.navNormal}>{t('Products')}</span>
                      </span>
                    </div>
                    <ChevronDown className={cn(s.arrow, { [s.arrow_ar]: router.locale == 'ar' })} />
                  </div>

                  <div className={cn(s.menulist, showSubNav === 'Products' ? s.menulistHover : s.menulistHide)}>
                    <div
                      className={cn(s.menuContent, {
                        [s.menuContent_ar]: router.locale == 'ar'
                      })}
                    >
                      <SolotionItem title={t('Software-as-a-Service')} list={productList} onClickMenu={hideMenu}></SolotionItem>
                      <SolotionItem title={t('Blockchain for Financial Services')} onClickMenu={hideMenu} list={serciveList}></SolotionItem>
                      <div>
                        <SolotionItem title={t('Asset Custody')} list={custodyList} onClickMenu={hideMenu}></SolotionItem>
                        <SolotionItem className={s.menuItem_especially} title={t('Asset Management')} list={managementList} onClickMenu={hideMenu}></SolotionItem>
                      </div>

                      <SolotionItem title={t('Web3 Infrastructure')} list={web3List} onClickMenu={hideMenu}></SolotionItem>
                    </div>
                  </div>
                </div>
                {/* Solutions */}
                <div
                  className={cn(s.navItem, {
                    [s.navItemHover]: showSubNav === 'Solutions' && !isH5,
                    [s.navItemActive]: clickNav == 'Solutions' && !isH5,
                    [s.navItemH5Hover]: showSubNav === 'Solutions' && isH5
                  })}
                  onMouseEnter={() => handleMouseEnter('Solutions')}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => {
                    onClickTab('Solutions')
                  }}
                >
                  <div className={s.menuBox}>
                    <div className={cn(s.innerWarpper)}>
                      <span className={cn(s.inner)}>
                        <span className={s.navNormal}>{t('Solutions')}</span>
                      </span>
                    </div>
                    <ChevronDown className={cn(s.arrow, { [s.arrow_ar]: router.locale == 'ar' })} />
                  </div>

                  <div className={cn(s.menulist, showSubNav === 'Solutions' ? s.menulistHover : s.menulistHide)}>
                    <div
                      className={cn(s.menuContent, {
                        [s.menuContent_ar]: router.locale == 'ar'
                      })}
                    >
                      {isH5 ? (
                        <div className={s.organizations_h5}>
                          <div className={cn(s.menuDesc, s.organizations_desc_h5)}>{t('ChainUp for Organizations')}</div>
                        </div>
                      ) : (
                        <div className={s.organizations}>
                          <p className={s.title}>{t('ChainUp for Organizations')}</p>
                          <p className={s.desc}>{t('Beyond legacy, embrace tomorrow: Evolve your institution with cutting-edge digital solutions.')}</p>
                        </div>
                      )}

                      {solutionsList.map((items, index) => (
                        <div key={index} className={s.solutions_warpper}>
                          {items.map((item, i) => (
                            <div key={i} className={cn(s.solutions_content, s.menuDesc)}>
                              <Link href={item.link} locale={router.locale}>
                                <a
                                  className={cn(s.solutions_title, { [s.retail_Banks_title]: !router.pathname.includes(item.link) }, { [s.menuDescCurrent]: router.pathname.includes(item.link) })}
                                  onClick={() => {
                                    hideMenu(item.link, 'Solutions')
                                  }}
                                >
                                  {item.title}
                                </a>
                              </Link>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* Resources */}

                <div
                  className={cn(s.navItem, {
                    [s.navItemHover]: showSubNav === 'Resources' && !isH5,
                    [s.navItemActive]: clickNav == 'Resources' && !isH5,
                    [s.navItemH5Hover]: showSubNav === 'Resources' && isH5
                  })}
                  onMouseEnter={() => handleMouseEnter('Resources')}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => {
                    onClickTab('Resources')
                  }}
                >
                  <div className={s.menuBox}>
                    <div className={cn(s.innerWarpper)}>
                      <span className={cn(s.inner)}>
                        <span className={s.navNormal}>{t('Insights')}</span>
                      </span>
                    </div>
                    <ChevronDown className={cn(s.arrow, { [s.arrow_ar]: router.locale == 'ar' })} />
                  </div>

                  <div className={cn(s.menulist, showSubNav === 'Resources' ? s.menulistHover : s.menulistHide)}>
                    <div
                      className={cn(s.menuContentUs, {
                        [s.menuContentUs_ar]: router.locale == 'ar'
                      })}
                    >
                      <MenuItem
                        isCheck={checkHref === '/news'}
                        label={t('ChainUp in the News')}
                        desc={t('From industry trends to company news, stay informed withus and get ahead in the world of blockchain technology')}
                        href="/news"
                        name="Resources"
                        onClickMenu={hideMenu}
                      />
                      <MenuItem
                        isCheck={checkHref === '/blog'}
                        label={t('Blog')}
                        desc={t("Enhance your understanding of the blockchain industry and keep up with the latest updates by exploring ChainUp's official blog")}
                        href="/blog"
                        name="Resources"
                        onClickMenu={hideMenu}
                      />
                      <MenuItem
                        isCheck={checkHref === '/marketupdates'}
                        label={t('Market Updates')}
                        desc={t('Quick and informative market updates on the blockchain industry')}
                        href="/marketupdates"
                        name="Resources"
                        onClickMenu={hideMenu}
                      />
                    </div>
                  </div>
                </div>
                {/* About Us */}
                <div
                  className={cn(s.navItem, {
                    [s.navItemHover]: showSubNav === 'AboutUs' && !isH5,
                    [s.navItemActive]: clickNav == 'AboutUs' && !isH5,
                    [s.navItemH5Hover]: showSubNav === 'AboutUs' && isH5
                  })}
                  onMouseEnter={() => handleMouseEnter('AboutUs')}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => {
                    onClickTab('AboutUs')
                  }}
                >
                  <div className={s.menuBox}>
                    <div className={cn(s.innerWarpper)}>
                      <span className={s.inner}>
                        <span className={s.navNormal}>{t('About Us')}</span>
                      </span>
                    </div>
                    <ChevronDown className={cn(s.arrow, { [s.arrow_ar]: router.locale == 'ar' })} />
                  </div>
                  <div className={cn(s.menulist, showSubNav === 'AboutUs' ? s.menulistHover : s.menulistHide)}>
                    <div
                      className={cn(s.menuContentUs, {
                        [s.menuContentUs_ar]: router.locale == 'ar'
                      })}
                    >
                      <MenuItem
                        isCheck={checkHref === '/about'}
                        label={t('About Us')}
                        desc={t(
                          'Headquartered in Singapore, ChainUp provides a complete suite of blockchain technology solutions for businesses, covering both infrastructure development and ecosystem support'
                        )}
                        href="/about"
                        name="AboutUs"
                        onClickMenu={hideMenu}
                      />
                      <MenuItem
                        isCheck={checkHref === '/team'}
                        label={t('Leadership')}
                        desc={t('ChainUp is led by a passionate team that is driven by a mission to make blockchain technology more accessible to businesses around the world')}
                        href="/team"
                        name="AboutUs"
                        onClickMenu={hideMenu}
                      />
                      <MenuItem
                        isCheck={checkHref === '/job'}
                        label={t('Careers')}
                        desc={t("We're looking for passionate people. Scroll down for open positions in our different offices")}
                        href="/job"
                        name="AboutUs"
                        onClickMenu={hideMenu}
                      />
                    </div>
                  </div>
                </div>
                {/* <Link href="/events" locale={'en'}> */}
                <a
                  className={cn(s.navItem, {
                    [s.navItemHover]: showSubNav === 'Event' && !isH5,
                    [s.navItemActive]: clickNav == 'Event' && !isH5,
                    [s.navItemH5Hover]: showSubNav === 'Event' && isH5
                  })}
                  onMouseEnter={() => handleMouseEnter('Event')}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => {
                    if (router.locale == 'en') {
                      router.push('/events')
                    } else {
                      window.location.href = '/' + 'en/events'

                    }
                    onClickTab('Event')
                    setClickNav('Event')
                  }}
                >
                  <div className={s.menuBox}>
                    <div className={cn(s.innerWarpper)}>
                      <span className={s.inner}>
                        <span className={s.navNormal}>{t('Events')}</span>
                      </span>
                    </div>
                  </div>
                </a>
                {/* </Link> */}

                {isH5 && (
                  <div className={s.mobileLang}>
                    {localArray.map((item, index) => {
                      return pathOnlyZhAndEn.indexOf(router.route.split('/')[1]) == -1 ||
                        (pathOnlyZhAndEn.indexOf(router.route.split('/')[1]) != -1 && (item.value == 'zh' || item.value == 'en') && router.route.split('/')[1] != 'events') ||
                        (router.route.split('/')[1] == 'events' && item.value == 'en') ? (
                        <div
                          className={cn(s.mobileItem, {
                            [s.mobileItemActive]: item.value == router.locale
                          })}
                          key={index}
                          onClick={() => changeLocale(item.value)}
                        >
                          {item.label}
                        </div>
                      ) : null
                    })}
                  </div>
                )}
              </div>
            </nav>
            {/* language */}
            <div className={cn(s.right)}>
              <div
                className={s.langPart}
                onMouseEnter={() => handleMouseEnter('lang')}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                  onClickTab('lang')
                }}
              >
                <span className={s.langLabel}>
                  <LangMap className={cn(s.langLabelImg, { [s.langLabelImg_ar]: router.locale == 'ar' })}></LangMap>
                  <span
                    className={cn(s.navTitle, {
                      [s.navTitleHover]: showSubNav === 'lang'
                    })}
                  >
                    {LocalMap[router.locale as LocalType]}
                  </span>
                  <ChevronDown className={cn(s.arrow, { [s.arrow_ar]: router.locale == 'ar' })} />
                </span>
                {showSubNav === 'lang' && (
                  <div className={cn(s.LngList, showSubNav === 'lang' ? s.menulistHover : s.menulistHide)}>
                    {localArray.map((item, index) => {
                      return pathOnlyZhAndEn.indexOf(router.route.split('/')[1]) == -1 ||
                        (pathOnlyZhAndEn.indexOf(router.route.split('/')[1]) != -1 && (item.value == 'zh' || item.value == 'en') && router.route.split('/')[1] != 'events') ||
                        (router.route.split('/')[1] == 'events' && item.value == 'en') ? (
                        <div key={index} className={cn(s.langItem, { [s.langItemCurrent]: item.value == router.locale })} onClick={() => changeLocale(item.value)}>
                          {item.label}
                          <Sure className={s.sure}></Sure>
                        </div>
                      ) : null
                    })}
                  </div>
                )}
              </div>
              <Link href="/request-a-Demo">
                <button
                  className={s.rightButton}
                  onMouseEnter={() => {
                    setHeaderTheam('white')
                  }}
                  onMouseLeave={() => {
                    setHeaderTheam('none')
                  }}
                >
                  {t('Request a demo')}
                </button>
              </Link>

              <div className={s.menuIcon} onClick={() => showMenu()}>
                {isMenuShow ? <Close style={{ width: '24px', height: '24px' }} /> : <Menu style={{ width: '24px', height: '24px' }} />}
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  )
}
export default React.memo(Header)
